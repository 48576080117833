<template>

<diV>
  
  <div class="card" style="max-height: 100px" >
    <div class="card-header">
    <b-row>
      <b-col
         md="2"
         xl="2"
      >
        <b-form-group
          label="Nº OP:"
        >
          <b-form-input
            id="IDIPROC"
            placeholder=""
            v-model="ApontamentoProducao.idiproc"
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="6"
      >
        <b-form-group
          label="Produto:"
        >
          <b-form-input
            placeholder=""
            v-model="ApontamentoProducao.descrprod"
            required
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
         md="4"
         xl="4"
      >
        <b-form-group
          label="Etapa:"
        >
          <b-form-input
            id="Etapa"
            placeholder=""
            v-model="ApontamentoProducao.descricaoetapa"
            readonly
            autocomplete="off"
          />
        </b-form-group>
      </b-col>


    </b-row>
  </div>
  </div>
  
  <div class="card">
    
    <b-row>
      <b-col
        md="12"
        xl="12"
      >
    <b-table
      striped       
      responsive
      sticky-header
      :items="this.MATERIAIS"
      :fields="fields"
      table-class = "font-size11" 
      style="max-height: 360px;"
    >

    <template #cell(SEQMP)="row">
    <div>
     

      <div class="DivBotaoLadoLado col-lg-1 col-md-1 col-xl-1">
          <b-form-group label="MP" >
             <b-form-input 
                    id="CODPRODMP"
                    v-model="row.item.CODPRODMP"
                    class="font-size11"
                    autofocus
                    autocomplete="off"
                    readonly
                    
                  />
          </b-form-group>
       </div>


      <div class="DivBotaoLadoLado col-lg-5 col-md-5 col-xl-5">
          <b-form-group label="Produto MP" >
             <b-form-input 
                    id="DESCRPROD"
                    v-model="row.item.DESCRPROD"
                    class="font-size11"
                    autofocus
                    autocomplete="off"
                    readonly
                  />
          </b-form-group>
       </div>

       <div class="DivBotaoLadoLado col-lg-1 col-md-1 col-xl-1">
          <b-form-group label="Unidade" >
             <b-form-input 
                    id="CODVOL"
                    v-model="row.item.CODVOL"
                    class="font-size11"
                    autofocus
                    autocomplete="off"
                    readonly
                    
                  />
          </b-form-group>
       </div>


       <div class="DivBotaoLadoLado InputVlr">
          <b-form-group label="Qtd. Apontada" >
             <b-form-input 
                    id="QTD"
                    v-model="row.item.QTD"
                    class="font-size11"
                    type="number"
                    autofocus
                    autocomplete="off"
                    @input="EditarMaterial(row.item)"
                    :readonly="PermiteEditarMaterial ? false : ''"
                  />
          </b-form-group>
       </div>

       <div class="DivBotaoLadoLado InputVlr"  v-if="1==2"  >
          <b-form-group label="Qtd. Perda" >
             <b-form-input 
                    id="QTDPERDA"
                    v-model="row.item.QTDPERDA"
                    class="font-size11"
                    type="number"
                    autocomplete="off"
                    @input="EditarMaterial(row.item)"
                    :readonly="PermiteEditarMaterial ? false : ''"
                  />
          </b-form-group>
       </div>

       <div class="DivBotaoLadoLado InputVlrLote"  >
          <b-form-group label="Lote" >
             <b-form-input 
                    id="CONTROLEMP"
                    v-model="row.item.CONTROLEMP"
                    class="font-size11"
                    autocomplete="off"
                    @input="EditarMaterial(row.item)"
                    :readonly="PermiteEditarMaterial ? false : ''"
                  />
          </b-form-group>
       </div>
      




      <div class="DivBotaoLadoLado col-1" v-if="PermiteEditarMaterial" >  
         <b-form-group label="" >
          
          
          <div class="DivBotaoLadoLado" v-if="MaterialEditando ==''"  >  
            <b-button 
               variant="danger"
               size="sm" @click="Remover(row.item)" class="mr-1 CtmBtn">
               <feather-icon
                   :icon="'Trash2Icon'"
                   size="15"
                />
                Excluir
            </b-button>
           </div>


          <div class="DivBotaoLadoLado" v-if="row.item.SEQMP+'-'+row.item.CODPRODMP == MaterialEditando" >  
          <b-button 
             variant="warning"
             size="sm" @click="Cancelar()" class="mr-1 CtmBtn">
             <feather-icon
                 :icon="'ArrowLeftCircleIcon'"
                 size="15"
              />
              Cancelar
          </b-button>
          </div>


          <div class="DivBotaoLadoLado"  v-if="row.item.SEQMP+'-'+row.item.CODPRODMP == MaterialEditando" >  
          
          <b-button 
             variant="success"
             size="sm" @click="Atualizar(row.item)" class="mr-1 CtmBtn">
             <feather-icon
                 :icon="'CheckIcon'"
                 size="15"
              />
            Atualizar
          </b-button>

          </div>  

         </b-form-group>                   
      </div>

    </div>
   </template>
 

    </b-table>
      
      </b-col>
    </b-row>

   <b-row>
      <b-col
        md="12"
        xl="12"
        class="mb-1"
      >

      
      <!-- modal trigger button -->
      <div class="DivBotaoLadoLado" v-if="PermiteEditarMaterial">
        <b-button
          v-b-modal.modalProduto
          variant="success"
        >
        <feather-icon
           :icon="'PlusCircleIcon'"
           size="15"
           /> 
            <span> Materias</span>
        </b-button>
      </div>
      


      <div class="DivBotaoLadoLado" >
        <b-button
           variant="danger"
           type="button"
           id="Retornar"
           @click="RedirecionaDetalhe()"
         >
         <span>RETORNAR</span>
        </b-button>
      </div>

      <modal-produto 
       :ModalPRODUTO="ModalPRODUTO"       
       @SelectedModalPRODUTO="ModalPRODUTO = $event"
       @SetModalPRODUTO="SetModalPRODUTO"
       
      />


    </b-col>

    </b-row>
  
</div>
  </diV>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BFormDatepicker,BTable, BFormSelect,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import ModalProduto from '@/views/ConsultaPopUP/modalProduto.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTable,
    BFormSelect,
    ModalProduto
    
  },
  data() {
    return {
      ApontamentoProducao: {
      },  
      NUAPO:'',
      SITUACAO:'',
      MATERIAIS:[],
      fields: [
        { key: 'SEQMP', label: ''  }
      ],
      PermiteAddMaterial: false,
      PermiteEditarMaterial: true,
      PermiteConfirmarCiclo: false,
      MaterialEditando: '',
      ModalPRODUTO:{
        TIPO:'PRODUTO_MP_APONTAMENTO',
        ATIVO:'S',
        IDIPROC:0
      },
    }
  },
  methods: {
    async EditarMaterial(Material) {

      if(this.MaterialEditando == '' || this.MaterialEditando == `${Material.SEQMP}-${Material.CODPRODMP}`)
      {
        this.PermiteAddMaterial = false
        this.MaterialEditando = `${Material.SEQMP}-${Material.CODPRODMP}`
      }else{
  
        let notification = { message: "Conclua a Edição anterior", alert_type: 'attention' }
        AlertaMsg.FormataMsg(notification)
      }

    },
    async Cancelar() {
      this.ConsultaMateriais()
      this.MaterialEditando = ""
      this.PermiteAddMaterial = true
    },  
    async Insere() {
     
     Spinner.StarLoad()

     let notification = ''

     let Material = {
      QTD: parseFloat(0),
      QTDPERDA: parseFloat(0),
      CODPRODMP: this.ModalPRODUTO.CODPROD,
      CODVOL: this.ModalPRODUTO.CODVOL,
      NUAPO: this.CICLO.NUAPO

     }     
    
     
     const usuario = this.$session.get('usuario')
     const URLpost = `/skOperacoesProducao/InsereMaterial?CODUSU=${usuario.codusu}`
     // Carrega os Dados no Objeto para enviar no Parametro
     const ParametroJson = JSON.parse(JSON.stringify(Material))
     await apiPortalSky.post(URLpost, ParametroJson, {
       headers: {
         'Content-Type': 'application/json',
       },
     }).then(response => {
       
       if (response.status === 200) {
         this.ConsultaMateriais()
       }
     })
       .catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })

       if(notification === '') {
         Spinner.EndLoad()
       }

    },
    async Atualizar(Material) {
     
     Spinner.StarLoad()

     let notification = ''

     Material.QTD = parseFloat(Material.QTD)
     Material.QTDPERDA = parseFloat(Material.QTDPERDA)
     
     const usuario = this.$session.get('usuario')
     const URLpost = `/skOperacoesProducao/AtualizarMaterial?CODUSU=${usuario.codusu}`
     // Carrega os Dados no Objeto para enviar no Parametro
     const ParametroJson = JSON.parse(JSON.stringify(Material))
     await apiPortalSky.post(URLpost, ParametroJson, {
       headers: {
         'Content-Type': 'application/json',
       },
     }).then(response => {
       
       if (response.status === 200) {
         this.ConsultaMateriais()
       }
     })
       .catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })

       if(notification === '') {
        
         Spinner.EndLoad()
         this.MaterialEditando = ""
         this.PermiteAddMaterial = true

       }

    },  
    async Remover(Material) {
     
     Spinner.StarLoad()

     let notification = ''

     const usuario = this.$session.get('usuario')
     const URLpost = `/skOperacoesProducao/RemoverMaterial?CODUSU=${usuario.codusu}`
     // Carrega os Dados no Objeto para enviar no Parametro
     const ParametroJson = JSON.parse(JSON.stringify(Material))
     await apiPortalSky.post(URLpost, ParametroJson, {
       headers: {
         'Content-Type': 'application/json',
       },
     }).then(response => {
       
       if (response.status === 200) {
         this.ConsultaMateriais()
       }
     })
       .catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })

       if(notification === '') {
        
         Spinner.EndLoad()
         this.MaterialEditando = ""
         this.PermiteAddMaterial = true

       }

    },  
    async ConsultaMateriais() {

      Spinner.StarLoad()      

      let notification = ''
      const usuario = this.$session.get('usuario')
      
      const URLpost = `/skOperacoesProducao/ConsultaApontamentoProducaoMaterial?CODUSU=${usuario.codusu}&IDIPROC=${this.ApontamentoProducao.IDIPROC}&NUAPO=${this.CICLO.NUAPO}`
      
      await apiPortalSky.post(URLpost, this.ApontamentoProducao.IDIPROC).then(response => {
        if (response.status === 200) {
          this.DescarregaDadosDoObjeto(response.data)          
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
        Spinner.EndLoad() 
      }
    },
    DescarregaDadosDoObjeto(data) {

      this.MATERIAIS = []
      this.MATERIAIS = data.MATERIAIS      
      
      if(this.ApontamentoProducao.status !=='F'){
        this.PermiteAddMaterial = true
      }

      if(this.CICLO.SITUACAO === 'C')
      {
         this.PermiteEditarMaterial = false
      }
      
      
    },
    RedirecionaDetalhe() {
      
      const $rota = `../apontamentoProducaoDetalhe/${this.ApontamentoProducao.idiproc}`
      this.$router.push($rota)
    },
    SetModalPRODUTO() {
      //this.FiltroTela.filtrarPorValue = this.ModalPRODUTO.DESCRPROD
      this.Insere()
    },
  },
  mounted() {
    if (this.$session.exists('ApontamentoProducao')) {
       if (this.$route.params.ad_ciclo != null && this.$route.params.ad_ciclo != "") {
          this.ApontamentoProducao = this.$session.get('ApontamentoProducao')

          let AD_CICLO = this.$route.params.ad_ciclo
          this.CICLO = this.ApontamentoProducao.ciclos.find(item => item.AD_CICLO === AD_CICLO)
        
          //this.ConsultarOpcoes()
          this.ConsultaMateriais()

          //Seta Valor para Consulta do Modal
          this.ModalPRODUTO.IDIPROC = this.ApontamentoProducao.idiproc
       }else{
        this.$router.push('../ApontamentoProducao')
       }  
       
    }else{
      this.$router.push('../ApontamentoProducao')
    }
    
  },
}
</script>


<style>
    .InputVlr{
       width: 110px;
      }
    .InputVlrLote{
       width: 125px;
      }
    .CtmBtn{
       width: 110px;
      }
</style>